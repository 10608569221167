import classNames from 'classnames';

import { ARF_MEOW_PHONE_NUMBER, FORMATTED_PHONE_NUMBER } from '@pumpkincare/shared';

import { Body1, ButtonStyles, Circle, SocialMedia, Typography } from '../../ui';

import styles from './error-page.module.css';

function ErrorPage() {
  return (
    <div className={styles.errorPage}>
      <div className={styles.circles}>
        <Circle size={64} classes={{ root: styles.circleYellow }} fill='plutoGold' />
        <Circle
          size={96}
          classes={{ root: styles.circleBlue }}
          fill='bluesCluesBlue'
        />
        <Circle size={152} classes={{ root: styles.circleRed }} fill='cliffordRed' />
      </div>

      <div className={styles.errorPageContent}>
        <h1 className={styles.h1}>Ruh Roh!</h1>

        <p className={classNames(Typography.h6, styles.errorText)}>
          Looks like something went wrong. Try visiting our homepage or contacting
          our support team.
        </p>

        <button
          className={classNames(ButtonStyles.primary, styles.homepageButton)}
          onClick={() => (location.href = '/')}
        >
          Visit Homepage
        </button>

        <h4 className={styles.h4}>Have questions? We’re a paw away.</h4>

        <Body1>
          {`${ARF_MEOW_PHONE_NUMBER} (${FORMATTED_PHONE_NUMBER}) | `}
          help@pumpkin.care
        </Body1>

        <SocialMedia
          containerProps={{ className: styles.socialLinks }}
          anchorProps={{
            className: styles.socialLinksItem,
          }}
          imageProps={{ className: styles.socialLinksImage }}
        />
      </div>
    </div>
  );
}

export default ErrorPage;
