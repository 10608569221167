import TextField, { TextFieldPropTypes } from '../text-field';

function ZipcodeField(props) {
  function handleZipcodeInputPaste(e) {
    e.preventDefault();

    const pasteText = e.clipboardData.getData('text/plain').replace(/[^0-9]/g, '');
    e.target.value = (e.target.value + pasteText).substring(0, 5);

    props.onChange(e);
    e.target.dispatchEvent(new Event('change'));
  }

  return (
    <TextField
      maxLength={5}
      onPaste={handleZipcodeInputPaste}
      pattern='[0-9]{9}'
      {...props}
    />
  );
}

ZipcodeField.propTypes = TextFieldPropTypes;

export default ZipcodeField;
