// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".vet-clinic_root_92f3f5e6 {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  align-items: flex-end;\n  gap: 8px 24px;\n}\n\n.vet-clinic_fullField_2878bcdb, .vet-clinic_halfField_c26c20f7 {\n  width: 100%;\n  padding-bottom: 8px;\n  margin: 0;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + " {\n  .vet-clinic_halfField_c26c20f7 {\n    width: calc(50% - 12px);\n  }\n}\n", "",{"version":3,"sources":["webpack://../../libs/vets/src/ui/vet-clinic/vet-clinic-form.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":["@value tablet from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n  align-items: flex-end;\n  gap: 8px 24px;\n}\n\n.fullField, .halfField {\n  width: 100%;\n  padding-bottom: 8px;\n  margin: 0;\n}\n\n@media tablet {\n  .halfField {\n    width: calc(50% - 12px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tablet": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["tablet"] + "",
	"root": "vet-clinic_root_92f3f5e6",
	"fullField": "vet-clinic_fullField_2878bcdb",
	"halfField": "vet-clinic_halfField_c26c20f7"
};
export default ___CSS_LOADER_EXPORT___;
