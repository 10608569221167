import { useQuery } from 'react-query';

import { postIdentitiesNextQuestionVariant } from './identity-service';

export const QUESTION_QUERY = 'questionQuery';

const PLACEHOLDER_DATA = {
  answer_options: [],
  id: '',
  img_src: '',
  variant_type: '',
  variant_text: '',
  subtitle: '',
  module: null,
};

export function useQuestion(identityId, petBreedSpecies) {
  return useQuery(
    [QUESTION_QUERY, identityId, petBreedSpecies],

    () =>
      postIdentitiesNextQuestionVariant(identityId, petBreedSpecies).then(
        response => {
          if (response.status === 200) {
            return response.data;
          } else if (response.status === 204) {
            return PLACEHOLDER_DATA;
          }
        }
      ),

    {
      enabled: !!identityId && !!petBreedSpecies,

      placeholderData: PLACEHOLDER_DATA,
    }
  );
}
